export const customTheme = {
 "colors": {
  "theme": {
   "base": {
    "page-bg": "#F3F7F5",
    "primary": "#314b3f",
    "success": "#50ab9f",
    "warning": "#ffa800",
    "danger": "#e8534f",
    "info": "#6e6db2",
    "dark": "#1B1B1B"
   },
   "light": {
    "primary": "#D6DBD9",
    "success": "#F1F9F7",
    "warning": "#fff4de",
    "danger": "#F9DDDC",
    "info": "#F3F3F7",
    "dark": "#D6D6D6"
   },
   "inverse": {
    "primary": "#ffffff",
    "success": "#ffffff",
    "warning": "#ffffff",
    "danger": "#ffffff",
    "info": "#ffffff",
    "dark": "#F5F5F5"
   },
   "hover": {
    "primary": "#203129",
    "success": "#339088",
    "warning": "#ee9d01",
    "danger": "#cb3129",
    "info": "#585996"
   }
  },
  "gray": {
   "gray100": "#F5F5F5",
   "gray200": "#F5F5F5",
   "gray300": "#E0E0E0",
   "gray400": "#D6D6D6",
   "gray500": "#B8B8B8",
   "gray600": "#7A7A7A",
   "gray700": "#5C5C5C",
   "gray800": "#333333",
   "gray900": "#1B1B1B"
  }
 }
}